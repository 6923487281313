import { FileIcon } from '@/icons';
import { useState } from 'react';

export default function Breadcrumbs(props: { path: string[] }) {
  const { path } = props;
  const breadcrumbPath = path.join(' / ');
  const [open, setOpen] = useState(false);

  const toggleDropdown = (): void => setOpen(!open);

  return (
    <div className="hd-relative hd-z-10">
      <button
        type="button"
        className="hd-flex hd-items-center hd-justify-center hd-rounded-md hd-p-1 active:hd-bg-gray-300 hocus:hd-bg-gray-200"
        onClick={toggleDropdown}
      >
        <div className="hd-h-5 hd-w-5 hd-opacity-[0.33]">
          <FileIcon />
        </div>

        <h3 className="hd-ml-4 hd-font-sans hd-font-semibold hd-leading-normal">{breadcrumbPath}</h3>
      </button>
    </div>
  );
}
