import { TMedia, TSortOption } from '@/types';

export const sortOptions: TSortOption[] = [
  {
    name: 'Upload date (newest first)',
    type: 'upload',
    ascending: true,
  },
  {
    name: 'Upload date (oldest first)',
    type: 'upload',
    ascending: false,
  },
  {
    name: 'Modified date (last modified first)',
    type: 'modified',
    ascending: true,
  },
  {
    name: 'Modified date (last modified last)',
    type: 'modified',
    ascending: false,
  },
  {
    name: 'Alphabetically A - Z',
    type: 'alphabetically',
    ascending: true,
  },
  {
    name: 'Alphabetically Z - A',
    type: 'alphabetically',
    ascending: false,
  },
];

export const sortMedia = (media: TMedia[], activeSortOption?: TSortOption): TMedia[] => {
  if (!activeSortOption) return media;
  let sortedMedia: TMedia[] = [];
  switch (activeSortOption.type) {
    case 'upload':
      sortedMedia = media
        .slice()
        .sort(
          (a, b) =>
            (activeSortOption.ascending ? 1 : -1) *
            (new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        );
      break;
    case 'modified':
      sortedMedia = media
        .slice()
        .sort(
          (a, b) =>
            (activeSortOption.ascending ? 1 : -1) *
            (new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
        );
      break;
    case 'alphabetically':
      sortedMedia = media.slice().sort(
        (a, b) =>
          (activeSortOption.ascending ? 1 : -1) *
          a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, {
            numeric: true,
            ignorePunctuation: true,
          })
      );

      break;
    default:
      // Default case, no sorting
      sortedMedia = media;
      break;
  }
  return sortedMedia;
};
