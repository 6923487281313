import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type TModal = {
  children: any;
  initialFocus?: React.MutableRefObject<null>;
  show: boolean;
  setShow: Function;
  width?: string;
};

export default function Modal({ show, setShow, width, children, initialFocus }: TModal) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="hd-w-full" onClose={() => setShow(false)} initialFocus={initialFocus}>
        <Transition.Child
          as={Fragment}
          enter="hd-ease-out hd-duration-300"
          enterFrom="hd-opacity-0"
          enterTo="hd-opacity-100"
          leave="hd-ease-in hd-duration-200"
          leaveFrom="hd-opacity-100"
          leaveTo="hd-opacity-0"
        >
          <div className="hd-fixed hd-inset-0 hd-z-50 hd-bg-gray-500 hd-bg-opacity-75 hd-transition-opacity" />
        </Transition.Child>

        <div className="hd-fixed hd-inset-0 hd-z-50 hd-w-screen hd-overflow-y-auto">
          <div className="hd-flex hd-min-h-full hd-items-center hd-justify-center hd-p-4 hd-text-center sm:hd-items-center sm:hd-p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`hd-relative hd-transform hd-overflow-hidden hd-rounded-lg hd-bg-white hd-px-4 hd-pb-4 hd-pt-5 hd-text-left hd-shadow-xl hd-transition-all ${width} sm:hd-my-8 sm:hd-p-6`}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.defaultProps = {
  initialFocus: null,
  width: 'sm:hd-w-full sm:hd-max-w-lg',
};
