import { TProject } from '@/types';
import { Menu } from '@headlessui/react';

function projectHeading(project: TProject) {
  return (
    <div className="hd-flex hd-items-center hd-justify-center hd-gap-2 hd-rounded-md active:hd-bg-gray-300 hocus:hd-bg-gray-200">
      <h1 className="hd-font-sans hd-text-base hd-font-semibold">{project.name}</h1>

      <div
        className={`hd-flex hd-items-center hd-justify-center hd-border hd-px-2 hd-py-1 ${project.status.bg} ${project.status.border} ${project.status.text} hd-rounded-md hd-text-sm hd-leading-none`}
      >
        <h2>{project.status.name}</h2>
      </div>
    </div>
  );
}

export default function ProjectSelector(props: { projects: TProject[] }) {
  const { projects } = props;

  return (
    <div className="hd-relative">
      <Menu>
        <Menu.Button
          type="button"
          className="hd-flex hd-items-center hd-justify-center hd-gap-2.5 hd-rounded hd-bg-gray-100 hd-px-2.5 hd-py-1.5 hd-font-sans hd-text-sm hd-font-semibold hd-leading-6 active:hd-bg-gray-300 hocus:hd-bg-gray-200"
        >
          {projectHeading(projects[0])}
        </Menu.Button>

        <Menu.Items className="hd-absolute hd-left-0 hd-top-full hd-mt-2 hd-w-56 hd-origin-top-right hd-scale-100 hd-transform hd-divide-y hd-divide-gray-100 hd-rounded-md hd-bg-white hd-opacity-100 hd-shadow-lg hd-ring-1 hd-ring-black hd-ring-opacity-5 focus:hd-outline-none">
          {projects.map((project: TProject) => (
            <Menu.Item key={project.name}>
              <button
                type="button"
                className="hd-w-full hd-whitespace-nowrap hd-px-4 hd-py-2 hd-text-left active:hd-bg-gray-300 hocus:hd-bg-gray-200"
              >
                <h3>{project.name}</h3>
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
}
