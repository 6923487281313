import { THumanFileSize } from '@/types/THumanFileSize';

/**
 * https://stackoverflow.com/questions/30922008/calculate-percentage-javascript
 *
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number): THumanFileSize {
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = -1;
  let value = bytes;

  // if size is smaller than 1kb return the size in bytes
  if (Math.abs(value) < 1024) {
    return { size: value, unit: 'bytes' };
  }

  do {
    value /= 1024;
    ++unitIndex;
  } while (Math.round(Math.abs(value) * 10) / 10 >= 1024 && unitIndex < units.length - 1);

  return { size: parseInt(value.toFixed(1), 10), unit: units[unitIndex] };
}

/*
 * https://stackoverflow.com/questions/30922008/calculate-percentage-javascript
 */
export const calculatePercentage = (partialValue: number, totalValue: number): string =>
  `${Math.floor((100 * partialValue) / totalValue)}%`;
