import { useRef } from 'react';

import { Button, ModalComponent } from '@/components';
import { PlusCircleIcon } from '@/icons';

type TFolderCreateComponentProps = {
  newFolderName: string;
  setNewFolderName: Function;
  handleCreateFolder: Function;
  show: boolean;
  setShow: Function;
};

export default function FolderCreateComponent({
  newFolderName,
  setNewFolderName,
  handleCreateFolder,
  show,
  setShow,
}: TFolderCreateComponentProps) {
  const inputRef = useRef(null);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      handleCreateFolder(newFolderName);
    } catch (error) {
      // eslint-disable-next-line
      console.error(error, 'test');
    }
  };

  return (
    <>
      <div>
        <Button className="hd-mb-3" onClick={() => setShow(true)}>
          <PlusCircleIcon />
          Folder aanmaken
        </Button>
      </div>

      <ModalComponent initialFocus={inputRef} show={show} setShow={setShow}>
        <form onSubmit={onSubmit} className="hd-w-full">
          <h2 className="hd-mb-4">Folder aanmaken</h2>

          <div>
            <input
              ref={inputRef}
              defaultValue={newFolderName}
              name="pathName"
              id="pathName"
              type="text"
              placeholder="New Folder"
              onChange={(e) => {
                setNewFolderName(e.target.value);
              }}
              required
            />
          </div>

          <div className="hd-col-4 hd-mt-4 hd-flex hd-w-full hd-flex-row hd-justify-between">
            <div>
              <Button
                className="!hd-bg-indigo-500 hd-text-white active:!hd-bg-indigo-900 hocus:!hd-bg-indigo-600"
                type="submit"
              >
                Folder aanmaken
              </Button>
            </div>

            <Button type="button" onClick={() => setShow(false)}>
              Close
            </Button>
          </div>
        </form>
      </ModalComponent>
    </>
  );
}
