import { Header, MediaComponent, MediaModalComponent } from '@/components';
import { TMedia, TSortOption } from '@/types';
import { useEffect, useState } from 'react';
import MediaDropFiles from './components/MediaDropFiles';
import { getFolders } from './api/folder';
import { deleteMedia, getMedia } from './api/media';
import FolderListComponent from './components/FolderListComponent';
import { ActiveMediaNotSetError } from './errors';
import './App.css';
import './grid.css';
import { sortMedia } from './services/sortingService';

function App() {
  const [media, setMedia] = useState<TMedia[]>([]);
  const [activeMedia, setActiveMedia] = useState<TMedia | null>(null);
  const [folderPaths, setFolderPaths] = useState<string[]>([]);
  const [activeSortOption, setActiveSortOption] = useState<TSortOption>();

  // Getting all media
  const loadMedia = async () => {
    try {
      const response = await getMedia();
      setMedia(sortMedia(response.data.files, activeSortOption));
    } catch (error) {
      setMedia([]);
      /**
       * ToDo: Add a toast message to show the error
       */
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // Delete posts
  async function handleDeleteMedia() {
    try {
      if (!activeMedia) throw new ActiveMediaNotSetError();
      if (!(await deleteMedia(activeMedia))) return;

      // After the delete action is completed
      // update the media list by removing the deleted media from the list
      setMedia(media.filter((item) => item.id !== activeMedia.id));
    } catch (error) {
      /**
       * ToDo: Add a toast message to show the error
       */
      // eslint-disable-next-line
      console.error(error);
    } finally {
      setActiveMedia(null);
    }
  }

  const loadFolders = async () => {
    try {
      const response = await getFolders();
      setFolderPaths(response.data);
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    loadMedia();
    loadFolders();
  }, []);

  useEffect(() => {
    setMedia(sortMedia(media, activeSortOption));
  }, [activeSortOption]);
  /* eslint-enable */

  return (
    <main className="hd-flex hd-h-screen hd-flex-col">
      <div className="hd-border-b hd-border-b-[#E4E4E4]">
        <Header onUpload={loadMedia} setActiveSortOption={setActiveSortOption} />
      </div>
      <div className="hd-px-6">
        <h1 className="hd-mb-5 hd-mt-12 hd-font-sans hd-text-lg hd-font-semibold">Folders</h1>
        {folderPaths && <FolderListComponent folders={folderPaths} />}
      </div>

      <MediaModalComponent
        setActiveMedia={setActiveMedia}
        activeMedia={activeMedia}
        handleDeleteMedia={() => handleDeleteMedia()}
      />
      <MediaDropFiles onUpload={loadMedia}>
        <h1 className="hd-mb-10 hd-mt-12 hd-font-sans hd-text-lg hd-font-semibold">Assets</h1>
        <ul className="hd-grid-container">
          {media.map((item: TMedia) => (
            <MediaComponent key={item.id} media={item} onClick={() => setActiveMedia(item)} />
          ))}
        </ul>
      </MediaDropFiles>
    </main>
  );
}
export default App;
