import { createMedia } from '@/api/media';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from '@/components';
import { PropsWithChildren, DragEvent, useState, useEffect, Fragment } from 'react';

type MediaDropFilesProps = PropsWithChildren<{
  onUpload: () => void;
}>;

export default function MediaDropFiles({ children, onUpload }: MediaDropFilesProps) {
  const [message, setMessage] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {}, [message]);

  const upload = (imageFileList: FileList) => {
    if (!imageFileList || imageFileList.length === 0) {
      setMessage((prevMessage) => [...prevMessage, 'Invalid image file list']);
    }

    const fileListArray = Array.from(imageFileList);
    setMessage([]);
    fileListArray.forEach((currentImage) => {
      if (!currentImage) return;
      createMedia(currentImage, () => {})
        .then((response) => {
          setMessage((prevMessage) => [...prevMessage, `${response.data.message} ${currentImage.name} `]);
          onUpload();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            setMessage((prevMessage) => [...prevMessage, `${err.response.data.message} ${currentImage.name}`]);
          } else {
            setMessage((prevMessage) => [...prevMessage, `${'Could not upload the Image!'} ${currentImage.name} `]);
          }
        });
    });
    toggleModal();
  };
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    upload(droppedFiles);
  };
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div className="hd-grow hd-px-6" onDrop={handleDrop} onDragOver={handleDragOver}>
      {children}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="hd-relative hd-z-50" onClose={toggleModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="hd-fixed hd-inset-0 hd-bg-black hd-bg-opacity-25" />
          </Transition.Child>

          <div className="hd-fixed hd-inset-0 hd-overflow-y-auto">
            <div className="hd-flex hd-min-h-full hd-items-center hd-justify-center hd-p-4 hd-text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="hd-w-full hd-max-w-md hd-transform hd-overflow-hidden hd-rounded-2xl hd-bg-white hd-p-6 hd-text-left hd-align-middle hd-shadow-xl hd-transition-all">
                  <Dialog.Title as="h3" className="hd-text-lg hd-font-medium hd-leading-6 hd-text-gray-900">
                    Message
                  </Dialog.Title>
                  {message && (
                    <>
                      <div className="hd-alert hd-alert-secondary hd-mt-3" role="alert">
                        <ul>
                          {message.map((item) => (
                            <li className="hd-m-2" key={item}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <Button onClick={toggleModal}>Close</Button>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
