/* eslint-disable react/button-has-type */
import { TButton } from '@/types';
import React from 'react';

export default function Button(props: TButton): React.JSX.Element {
  const { className, children, onClick, buttonRef, type } = props;

  return (
    <button
      type={type}
      className={`hd-flex hd-items-center hd-justify-center hd-gap-2.5 hd-rounded hd-bg-gray-100 hd-px-2.5 hd-py-1.5 hd-font-sans hd-text-sm hd-font-semibold hd-leading-6 active:hd-bg-gray-300 hocus:hd-bg-gray-200 ${className}`}
      onClick={onClick}
      ref={buttonRef}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  type: 'button',
};
