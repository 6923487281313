import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import './index.css';

import { SENTRY_DSN_TOKEN } from './lib/config';

Sentry.init({
  dsn: SENTRY_DSN_TOKEN,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
