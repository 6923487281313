import { CouldNotDeleteMediaError, MediaNotFoundError } from '@/errors';
import { TMedia } from '@/types';
import client from './client';

type TMediaGetResponse = {
  data: TMediaResponse;
  message: string;
  success: boolean;
};

type TMediaDeleteResponse = {
  message: string;
  success: boolean;
};

type TMediaResponse = {
  files: TMedia[];
  directories: string[];
};

const getMedia = (): Promise<TMediaGetResponse> =>
  client
    .get('media')
    .then((response): TMediaGetResponse => response.data)
    .catch((error) => {
      throw new MediaNotFoundError(error.message);
    });

const deleteMedia = (media: TMedia): Promise<TMediaDeleteResponse> =>
  client
    .delete(`/media/${media.id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new CouldNotDeleteMediaError(error.message);
    });

const createMedia = (file: File, onUploadProgress: any): Promise<any> => {
  const formData = new FormData();
  formData.append('media', file);
  formData.append('visibility', 'private');

  return client.post('media', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

const updateMedia = (media: TMedia, visibility: string): Promise<any> => {
  return client.patch(`/media/${media.id}`, { visibility });
};

export { createMedia, deleteMedia, getMedia, updateMedia };
