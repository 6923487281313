import { CouldNotDeleteFolderError } from '@/errors';
import client from './client';

type TFolderGetResponse = {
  data: string[];
  message: string;
  success: boolean;
};

type TFolderDeleteResponse = {
  message: string;
  success: boolean;
};

type TFolderCreateResponse = {
  data: TFolderResponse;
  message: string;
  success: boolean;
};

type TFolderResponse = {
  path: string;
};

export async function createFolder(folderName: string): Promise<TFolderCreateResponse> {
  return client
    .post(`folders`, { path: folderName })
    .then((response: { data: TFolderCreateResponse }): TFolderCreateResponse => response.data);
}

export async function deleteFolder(path: string): Promise<TFolderDeleteResponse> {
  return client
    .delete(`/folders/:folder?path=${path}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new CouldNotDeleteFolderError(error.message);
    });
}

export async function getFolders(path: string = '/'): Promise<TFolderGetResponse> {
  return client.get(`folders?path=${path}`).then((response): TFolderGetResponse => response.data);
}
