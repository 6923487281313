import getBucketSize from '@/api/bucket';
import { Breadcrumbs, Button, MediaUploadModalComponent, ProjectSelector } from '@/components';
import { calculatePercentage, humanFileSize } from '@/helpers/sizes';
import { AngleRightIcon, TrashIcon } from '@/icons';
import { sortOptions } from '@/services/sortingService';
import { THumanFileSize, TProject, TSortOption } from '@/types';
import { Menu } from '@headlessui/react';
import { useEffect, useState } from 'react';

type THeaderProps = {
  onUpload: () => void;
  setActiveSortOption: (option: TSortOption) => void;
};

const projects: TProject[] = [
  {
    name: 'Project 1',
    status: {
      name: 'In verkoop',
      border: 'hd-border-sale-border',
      bg: 'hd-bg-sale-bg',
      text: 'hd-text-sale-text',
    },
  },
  {
    name: 'Project 2',
    status: {
      name: 'Verkocht',
      border: 'hd-border-sold-border',
      bg: 'hd-bg-sold-bg',
      text: 'hd-text-sold-text',
    },
  },
];

const path: string[] = ['Bestanden', 'Project 1'];

export default function Header(props: THeaderProps) {
  const { onUpload, setActiveSortOption } = props;

  const [usedSizeBytes, setUsedSizeBytes] = useState<number>(0);
  const [usedSize, setUsedSize] = useState<THumanFileSize>();

  const bucketSizeBytes: number = 268435456000;
  const bucketSize: THumanFileSize = humanFileSize(bucketSizeBytes);

  const getUsedSize = async () => {
    await getBucketSize()
      .then((res) => {
        const size = parseInt(res.size, 10);
        setUsedSizeBytes(size);
        setUsedSize(humanFileSize(size));
      })
      .catch((error) => {
        /**
         * ToDo: Add a toast message to show the error
         */
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  useEffect(() => {
    getUsedSize();
  }, []);

  return (
    <div className="hd-flex hd-w-full hd-flex-col hd-gap-2.5 hd-px-6 hd-pb-1 hd-pt-3">
      <div className="hd-flex hd-flex-wrap hd-items-center hd-gap-5">
        <div className="hd-relative hd-z-20">
          <ProjectSelector projects={projects} />
        </div>

        <div className="hd-h-4 hd-opacity-[0.33]">
          <AngleRightIcon />
        </div>

        <Breadcrumbs path={path} />
      </div>

      <div className="hd-relative hd-z-10 hd-flex hd-flex-wrap hd-justify-between hd-gap-2.5">
        <div className="hd-flex hd-flex-wrap hd-justify-between hd-gap-2.5">
          <div className="hd-flex hd-flex-wrap hd-items-center hd-gap-1">
            <MediaUploadModalComponent onUpload={onUpload} />

            <Button className="">
              <TrashIcon />
              Delete
            </Button>
          </div>
        </div>

        <div className="hd-flex hd-items-center hd-justify-between hd-gap-[10vw]">
          <div className="hd-relative hd-flex hd-gap-1">
            <Menu>
              <Menu.Button className="hd-flex hd-items-center hd-justify-center hd-gap-2.5 hd-rounded hd-bg-gray-100 hd-px-2.5 hd-py-1.5 hd-font-sans hd-text-sm hd-font-semibold hd-leading-6 active:hd-bg-gray-300 hocus:hd-bg-gray-200">
                Sorteren
              </Menu.Button>
              <Menu.Items className="hd-absolute hd-right-0 hd-top-full hd-mt-2 hd-w-56 hd-origin-top-right hd-scale-100 hd-transform hd-divide-y hd-divide-gray-100 hd-rounded-md hd-bg-white hd-opacity-100 hd-shadow-lg hd-ring-1 hd-ring-black hd-ring-opacity-5 focus:hd-outline-none">
                {sortOptions.map((sortOption: TSortOption) => (
                  <Menu.Item key={sortOption.name}>
                    <button
                      type="button"
                      className="hd-w-full hd-whitespace-nowrap hd-px-4 hd-py-2 hd-text-left active:hd-bg-gray-300 hocus:hd-bg-gray-200"
                      key={sortOption.name}
                      onClick={() => setActiveSortOption(sortOption)}
                    >
                      <h3>{sortOption.name}</h3>
                    </button>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>

            <Menu>
              <Menu.Button className="hd-flex hd-items-center hd-justify-center hd-gap-2.5 hd-rounded hd-bg-gray-100 hd-px-2.5 hd-py-1.5 hd-font-sans hd-text-sm hd-font-semibold hd-leading-6 active:hd-bg-gray-300 hocus:hd-bg-gray-200">
                Filteren
              </Menu.Button>
              <Menu.Items className="hd-absolute hd-right-0 hd-top-full hd-mt-2 hd-w-56 hd-origin-top-right hd-scale-100 hd-transform hd-divide-y hd-divide-gray-100 hd-rounded-md hd-bg-white hd-opacity-100 hd-shadow-lg hd-ring-1 hd-ring-black hd-ring-opacity-5 focus:hd-outline-none">
                {projects.map((project: TProject) => (
                  <Menu.Item key={project.name}>
                    <button
                      type="button"
                      className="hd-w-full hd-whitespace-nowrap hd-px-4 hd-py-2 hd-text-left active:hd-bg-gray-300 hocus:hd-bg-gray-200"
                      key={project.name}
                    >
                      <h3>{project.name}</h3>
                    </button>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </div>

          <div className="hd-flex hd-flex-col hd-font-sans">
            <span className="hd-text-gray-400">
              <span className="hd-text-black">{usedSize ? usedSize.size : '...'}</span>
              <span className="hd-text-sm hd-font-bold hd-text-black">{usedSize && usedSize.unit}</span> /{' '}
              <span>
                {bucketSize.size}
                <span className="hd-text-sm hd-font-bold">{bucketSize.unit}</span>
              </span>
            </span>
            <div className="hd-h-[3px] hd-w-36 hd-bg-gray-100">
              <div
                className="hd-h-full hd-bg-black"
                style={{ width: calculatePercentage(usedSizeBytes, bucketSizeBytes) }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
