import { PlaceholderIcon } from '@/icons';
import { TMedia } from '@/types/TMedia';
import { MouseEventHandler, useState } from 'react';

type TMediaProp = {
  media: TMedia;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export default function MediaComponent(props: TMediaProp) {
  const { media, onClick } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <button
      type="button"
      onClick={onClick}
      key={media.id}
      className="hd-outline-solid hd-flex hd-aspect-[219/198] hd-flex-col hd-items-stretch hd-overflow-hidden hd-rounded-lg hd-bg-neutral-100 hd-text-left hd-outline hd-outline-1 hd-outline-[#EDEDED] active:hd-border-blue-500 hocus:hd-outline-2 hocus:hd-outline-blue-500"
    >
      <div className="hd-relative hd-h-24 hd-flex-shrink-0 hd-flex-grow hd-overflow-hidden hd-bg-white">
        <div className="hd-d-block hd-pointer-events-none hd-absolute hd-h-full hd-w-full hd-bg-gray-300">
          <PlaceholderIcon />
        </div>

        <img
          className={`hd-pointer-events-none hd-absolute hd-h-full hd-w-full hd-bg-transparent hd-object-cover hd-transition-opacity hd-duration-300 ${
            imageLoaded ? 'hd-opacity-100' : 'hd-opacity-0'
          }`}
          src={media.url}
          alt={media.alt}
          onLoad={() => setImageLoaded(true)}
          loading="lazy"
        />
      </div>

      <div className="hd-flex hd-flex-shrink-0 hd-flex-grow-0 hd-flex-row hd-content-center hd-bg-white/40 hd-px-4 hd-py-3">
        <p className="hd-font-sans hd-text-sm hd-font-bold hd-leading-6">{media.name}</p>
      </div>
    </button>
  );
}
