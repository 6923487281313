import { Button } from '@/components';
import { FolderIcon, TrashIcon } from '@/icons';
import { useState } from 'react';

export type TFolderComponentProps = {
  path: string;
  onClick: () => void;
  handleDeleteFolder: Function;
};

export default function FolderComponent(props: TFolderComponentProps) {
  const { path, onClick, handleDeleteFolder } = props;
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className="hd-flex hd-flex-row hd-content-baseline hd-justify-between hd-gap-4 hd-rounded-md hd-border hd-border-solid hd-border-black hd-p-4 hocus:hd-bg-gray-200">
      <button
        type="button"
        onClick={() => onClick}
        onMouseOver={() => setShow(true)}
        onFocus={() => setShow(true)}
        onMouseOut={() => setShow(false)}
        onBlur={() => setShow(false)}
      >
        <div className="hd-flex hd-items-center hd-gap-2">
          <div className="hd-flex-shrink-0">
            <FolderIcon />
          </div>

          <span
            className="hd-pointer-events-none hd-block hd-max-w-full hd-overflow-hidden hd-text-ellipsis hd-whitespace-nowrap hd-font-bold"
            title={`${path}`}
          >
            {path}
          </span>
        </div>
      </button>
      <Button className={`hd-content-baseline ${show || 'hd-invisible'}`} onClick={() => handleDeleteFolder()}>
        <TrashIcon />
      </Button>
    </div>
  );
}
