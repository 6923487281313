import { createFolder, deleteFolder } from '@/api/folder';
import { FolderComponent, FolderCreateComponent, WarningModalComponent } from '@/components';
import { useEffect, useState } from 'react';

type TFolderListComponentProps = {
  folders: string[];
};

export default function FolderListComponent({ folders }: TFolderListComponentProps) {
  const [showWarning, setShowWarning] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [folderPaths, setFolderPaths] = useState<string[]>([]);
  const [activeFolder, setActiveFolder] = useState<string>('');

  const handleCreateFolder = async (name: string) => {
    await createFolder(name).then((response) => {
      setFolderPaths([...folderPaths, response.data.path]);
      setShowCreateModal(false);
      setNewFolderName('');
    });
  };

  const handleDeleteFolder = async (path: string) => {
    try {
      if (!(await deleteFolder(path))) return;
      setFolderPaths(folderPaths.filter((folderPath: string) => folderPath !== path));
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  const handleDeleteFolderClick = (path: string) => {
    setActiveFolder(path);
    setShowWarning(true);
  };

  useEffect(() => {
    setFolderPaths(folders);
  }, [folders]);

  return (
    <>
      <FolderCreateComponent
        setNewFolderName={setNewFolderName}
        newFolderName={newFolderName}
        show={showCreateModal}
        setShow={setShowCreateModal}
        handleCreateFolder={(name: string) => handleCreateFolder(name)}
      />

      <WarningModalComponent
        title="Are you sure you want to delete this folder?"
        message="Deleting this folder is permanent and can't be undone."
        buttonTitle="Delete Folder"
        modalFunction={() => handleDeleteFolder(activeFolder)}
        showWarning={showWarning}
        setShowWarning={setShowWarning}
      />

      <ul className="hd-grid-container">
        {folderPaths.map((path) => (
          <FolderComponent
            key={`folder-${path}`}
            path={path}
            onClick={() => {}}
            handleDeleteFolder={() => handleDeleteFolderClick(path)}
          />
        ))}
      </ul>
    </>
  );
}
