import ModalComponent from '@/components/ModalComponent';
import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useRef } from 'react';

type TWarningModalProps = {
  title: string;
  message: string;
  modalFunction: Function;
  buttonTitle: string;
  showWarning: boolean;
  setShowWarning: (value: boolean) => void;
};

export default function WarningModalComponent(props: TWarningModalProps) {
  const { title, message, modalFunction, buttonTitle, showWarning, setShowWarning } = props;

  const cancelButtonRef = useRef(null);

  const handleModalFunctionClicked = () => {
    modalFunction();
    setShowWarning(false);
  };

  return (
    <ModalComponent initialFocus={cancelButtonRef} show={showWarning} setShow={setShowWarning}>
      <div className=" sm:hd-flex sm:hd-items-start">
        <div className="hd-mx-auto hd-flex hd-h-12 hd-w-12 hd-flex-shrink-0 hd-items-center hd-justify-center hd-rounded-full hd-bg-red-100 sm:hd-mx-0 sm:hd-h-10 sm:hd-w-10">
          <ExclamationTriangleIcon className="hd-h-6 hd-w-6 hd-text-red-600" aria-hidden="true" />
        </div>
        <div className="hd-mt-3 hd-text-center sm:hd-ml-4 sm:hd-mt-0 sm:hd-text-left">
          <Dialog.Title as="h3" className="hd-text-base hd-font-semibold hd-leading-6 hd-text-gray-900">
            {title}
          </Dialog.Title>
          <div className="hd-mt-2">
            <p className="hd-text-sm hd-text-gray-500">{message}</p>
          </div>
        </div>
      </div>
      <div className="hd-mt-5 sm:hd-ml-10 sm:hd-mt-4 sm:hd-flex sm:hd-pl-4">
        <button
          type="button"
          className="hd-inline-flex hd-w-full hd-justify-center hd-rounded-md hd-bg-red-600 hd-px-3 hd-py-2 hd-text-sm hd-font-semibold hd-text-white hd-shadow-sm hover:hd-bg-red-500 sm:hd-w-auto"
          onClick={() => handleModalFunctionClicked()}
        >
          {buttonTitle}
        </button>
        <button
          type="button"
          className="hd-mt-3 hd-inline-flex hd-w-full hd-justify-center hd-rounded-md hd-bg-white hd-px-3 hd-py-2 hd-text-sm hd-font-semibold hd-text-gray-900 hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-gray-300 hover:hd-bg-gray-50 sm:hd-ml-3 sm:hd-mt-0 sm:hd-w-auto"
          onClick={() => setShowWarning(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </ModalComponent>
  );
}
