import { updateMedia } from '@/api/media';
import { ModalComponent, WarningModalComponent } from '@/components';
import { ActiveMediaNotSetError } from '@/errors';
import { humanFileSize } from '@/helpers/sizes';
import { TMedia } from '@/types';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@headlessui/react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { ChangeEvent, useRef, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';

type TMediaModalProp = {
  activeMedia: TMedia | null;
  handleDeleteMedia: Function;
  setActiveMedia: (value: null) => void;
};

export default function MediaModalComponent(props: TMediaModalProp) {
  const { activeMedia, handleDeleteMedia, setActiveMedia } = props;

  const cancelButtonRef = useRef(null);
  const [showWarning, setShowWarning] = useState(false);
  const [visibility, setVisibility] = useState<string>('private');

  TimeAgo.addLocale(en);

  const handleClickCloseButton = () => {};

  const handleVisibilityChange = (e: ChangeEvent) => {
    setVisibility((e.target as HTMLSelectElement).value);
  };

  const handleSaveButton = () => {
    try {
      if (!activeMedia) throw new ActiveMediaNotSetError();
      updateMedia(activeMedia, visibility);
    } catch (error) {
      /**
       * ToDo: Add a toast message to show the error
       */
      // eslint-disable-next-line
      console.error(error);
    } finally {
      setActiveMedia(null);
    }
  };

  return (
    <>
      <WarningModalComponent
        title="Are you sure you want to delete this media?"
        message="Deleting this media is permanent and can't be undone."
        buttonTitle="Delete Media"
        modalFunction={handleDeleteMedia}
        showWarning={showWarning}
        setShowWarning={setShowWarning}
      />

      {activeMedia && (
        <ModalComponent
          show={activeMedia !== null}
          setShow={() => setActiveMedia(null)}
          initialFocus={cancelButtonRef}
          width="hd-w-[80vw]"
        >
          <Dialog.Panel className="hd-modal w-full hd-flex hd-flex-col hd-bg-white lg:hd-flex-row xl:hd-max-h-[75vh] xl:hd-min-h-[65vh]">
            {/* the left side, image */}
            <div className="hd-flex hd-flex-col hd-content-center hd-justify-center hd-p-4 lg:hd-w-7/12">
              <img className="hd-h-full hd-object-contain " src={activeMedia.url} alt={activeMedia.alt} />
            </div>
            {/* the right side, all info, inputs and buttons */}
            <div className="hd-p-8 lg:hd-w-6/12">
              {/* name and close button */}
              <div className="hd-flex hd-flex-row hd-justify-between">
                <h1 className="hd-text-xl hd-font-bold">{activeMedia.name}</h1>
                <button
                  aria-label="Close Modal"
                  type="button"
                  onClick={() => handleClickCloseButton()}
                  className="hd-text-lg"
                >
                  <FontAwesomeIcon icon={faX} />
                </button>
              </div>
              {/* dimensions, size and last modified info */}
              <div className="hd-mt-4 hd-flex hd-flex-col hd-justify-between md:hd-flex-row lg:hd-flex-col xl:hd-flex-row">
                <div className="hd-mb-4 sm:hd-mb-0">
                  <p className="hd-font-bold">Dimensions</p>
                  <p>
                    {activeMedia.width} x {activeMedia.height}
                  </p>
                </div>
                <div className="hd-mb-4 sm:hd-mb-0">
                  <p className="hd-font-bold">Size</p>
                  <p>
                    {humanFileSize(parseInt(activeMedia.size, 10)).size}
                    {humanFileSize(parseInt(activeMedia.size, 10)).unit}
                  </p>
                </div>
                <div className="hd-mb-4 sm:hd-mb-0">
                  <p className="hd-font-bold">Last Modified</p>
                  <ReactTimeAgo date={new Date(activeMedia.updated_at)} locale="en-US" />
                </div>
              </div>
              {/* rename, replace, copy image url buttons and visibility select */}
              <div className="hd-mt-4 hd-flex hd-flex-col hd-justify-between hd-gap-4 md:hd-flex-row lg:hd-flex-col xl:hd-flex-row">
                <div className="hd-flex hd-flex-col hd-justify-between hd-gap-4 md:hd-flex-row lg:hd-flex-col xl:hd-flex-row">
                  <button
                    type="button"
                    className="hd-mb-4 hd-h-12 hd-rounded-md hd-bg-white hd-px-3.5 hd-py-2.5 hd-text-sm hd-text-gray-900  hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-black hover:hd-bg-gray-50 "
                  >
                    Rename
                  </button>
                  <button
                    type="button"
                    className="hd-mb-4 hd-h-12 hd-rounded-md hd-bg-white hd-px-3.5 hd-py-2.5 hd-text-sm  hd-text-gray-900 hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-black hover:hd-bg-gray-50 "
                  >
                    Replace
                  </button>
                  <button
                    type="button"
                    className="hd-mb-4 hd-h-12 hd-rounded-md hd-bg-white hd-px-3.5 hd-py-2.5 hd-text-sm hd-text-gray-900 hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-black hover:hd-bg-gray-50 "
                  >
                    Copy Image URL
                  </button>
                </div>
                {/* ToDo: Add a way of detecting which visibility is currently selected */}
                <select
                  className=" hd-h-12 hd-rounded-md hd-border-black hd-px-3.5 hd-py-2.5 hd-text-center hd-text-sm hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-black"
                  name="visibility"
                  id="options"
                  onChange={handleVisibilityChange}
                  value={visibility}
                >
                  <option value="private">private</option>
                  <option value="public">public</option>
                </select>
              </div>
              <h2 className="hd-mt-10 hd-text-xl hd-font-semibold">Fields</h2>
              {/* input fields for alt and label */}
              <div>
                <div className="hd-relative hd-mt-2">
                  <label htmlFor="alt">
                    <span className="hd-absolute hd-left-4 hd-top-2 hd-text-sm hd-font-semibold hd-text-gray-900">
                      Alt
                    </span>
                    <input
                      id="alt"
                      type="text"
                      name="alt"
                      className="hd-block hd-w-full hd-rounded-md hd-border-0 hd-p-4 hd-py-2.5 hd-pt-8 hd-text-gray-900 hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-[#9CA3AF] placeholder:hd-text-gray-600 focus:hd-ring-2 focus:hd-ring-inset focus:hd-ring-indigo-600  sm:hd-leading-6"
                      placeholder="Placeholder"
                    />
                  </label>
                </div>
                <div className="hd-relative hd-mt-2">
                  <label htmlFor="label">
                    <span className="hd-absolute hd-left-4 hd-top-2 hd-text-sm hd-font-semibold hd-text-gray-900">
                      Label
                    </span>
                    <input
                      type="text"
                      name="label"
                      id="label"
                      className="hd-block hd-w-full hd-rounded-md hd-border-0 hd-p-4 hd-py-2.5 hd-pt-8 hd-text-gray-900 hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-[#9CA3AF] placeholder:hd-text-gray-600 focus:hd-ring-2 focus:hd-ring-inset focus:hd-ring-indigo-600  sm:hd-leading-6"
                      placeholder="Placeholder"
                    />
                  </label>
                </div>
              </div>

              {/* save, download and delete buttons */}
              <div className="hd-my-10 hd-flex hd-flex-row hd-justify-between hd-gap-4">
                <button
                  type="button"
                  className="hd-rounded-md hd-bg-black hd-px-3.5 hd-py-2.5 hd-text-sm hd-font-semibold hd-text-white hd-shadow-sm hover:hd-bg-indigo-500 focus-visible:hd-outline focus-visible:hd-outline-2 focus-visible:hd-outline-offset-2 focus-visible:hd-outline-indigo-600"
                  onClick={handleSaveButton}
                >
                  Save
                </button>
                <div className="hd-flex hd-gap-4">
                  <a
                    className="hd-rounded-md hd-bg-white hd-px-3.5 hd-py-2.5  hd-text-sm hd-text-gray-900 hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-black hover:hd-bg-gray-50"
                    download={activeMedia.url}
                    href={activeMedia.url}
                  >
                    Download
                  </a>
                  <button
                    onClick={() => setShowWarning(true)}
                    type="button"
                    className="hd-rounded-md hd-bg-white hd-px-3.5 hd-py-2.5 hd-text-sm  hd-text-gray-900 hd-shadow-sm hd-ring-1 hd-ring-inset hd-ring-black hover:hd-bg-gray-50"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </ModalComponent>
      )}
    </>
  );
}
