import BucketNotFoundError from '@/errors/BucketNotFoundError';
import client from './client';

type TBucketResponse = {
  size: string;
};

export default function getBucketSize(): Promise<TBucketResponse> {
  return client
    .get('/size')
    .then((response): TBucketResponse => response.data)
    .catch((error) => {
      throw new BucketNotFoundError(error.message);
    });
}
