import { createMedia } from '@/api/media';
import { Button } from '@/components';
import { PlusCircleIcon } from '@/icons';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, PropsWithChildren, useEffect, useState } from 'react';

type MediaUploadModalComponentProps = PropsWithChildren<{
  onUpload: () => void;
}>;

export default function MediaUploadModalComponent({ onUpload }: MediaUploadModalComponentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [message, setMessage] = useState<string[]>([]);
  const [currentImages, setCurrentImages] = useState<File[]>([]);

  useEffect(() => {}, [message]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setMessage([]);
  };

  const selectImages = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;
    const selectedImages: File[] = Array.from(selectedFiles);
    setCurrentImages([...currentImages, ...selectedImages]);

    const newPreviews = Array.from(selectedImages).map((element) => URL.createObjectURL(element));
    setPreviewImages([...previewImages, ...newPreviews]);
  };

  const upload = (selectedImages: File[]) => {
    if (!selectedImages) return;
    setMessage([]);
    selectedImages.forEach((currentImage) => {
      createMedia(currentImage, () => {})
        .then((response) => {
          setMessage((prevMessage) => [...prevMessage, `${response.data.message} ${currentImage.name} `]);
          onUpload();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            setMessage((prevMessage) => [...prevMessage, `${err.response.data.message} ${currentImage.name}`]);
          } else {
            setMessage((prevMessages) => [...prevMessages, `${'Could not upload the Image!'}  ${currentImage.name}`]);
          }
        });
    });
    setPreviewImages([]);
    setCurrentImages([]);
  };

  return (
    <>
      <div>
        <Button type="button" onClick={toggleModal}>
          <PlusCircleIcon />
          Bestand toevoegen
        </Button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="hd-relative hd-z-50" onClose={toggleModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="hd-fixed hd-inset-0 hd-bg-black hd-bg-opacity-25" />
          </Transition.Child>

          <div className="hd-fixed hd-inset-0 hd-overflow-y-auto">
            <div className="hd-flex hd-min-h-full hd-items-center hd-justify-center hd-p-4 hd-text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="hd-max-w-screen-lg hd-transform hd-overflow-hidden hd-rounded-2xl hd-bg-white hd-p-6 hd-text-left hd-align-middle hd-shadow-xl hd-transition-all">
                  <Dialog.Title as="h3" className="hd-text-lg hd-font-medium hd-leading-6 hd-text-gray-900">
                    Upload an image
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="hd-relative hd-z-50">
                      <div className="hd-row">
                        <div className="hd-col-8">
                          <label htmlFor="upload" className="hd-btn hd-btn-default hd-p-0">
                            Upload
                            <input
                              id="upload"
                              className="active:hd-file:bg-gray-300 hocus:hd-file:bg-gray-200 hd-block hd-w-full hd-text-sm hd-text-slate-500 file:hd-mr-4 file:hd-rounded-md file:hd-border-0 file:hd-bg-gray-100 file:hd-px-4 file:hd-py-2 file:hd-text-sm file:hd-font-semibold file:hd-text-black"
                              type="file"
                              accept="image/*"
                              onChange={selectImages}
                              multiple
                            />
                          </label>
                        </div>
                        <div className="hd-mt-2 hd-grid hd-grid-cols-3 hd-gap-3">
                          {previewImages.map((item) => (
                            <div className="hd-border-2" key={item}>
                              {item && <img className="hd-preview hd-object-cover " src={item} alt="" />}
                            </div>
                          ))}
                        </div>

                        {message && (
                          <div className="hd-alert hd-alert-secondary hd-mt-3" role="alert">
                            <ul>
                              {message.map((item) => (
                                <li className="hd-m-2" key={item}>
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        <div className="hd-col-4 hd-mt-4 hd-flex hd-w-full hd-flex-row hd-justify-between">
                          <Button
                            className="!hd-bg-indigo-500 hd-text-white active:!hd-bg-indigo-900 hocus:!hd-bg-indigo-600"
                            onClick={() => {
                              upload(currentImages);
                            }}
                          >
                            Upload
                          </Button>

                          <Button onClick={toggleModal}>Close</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
